import api from "@/api/api";
import {encrypt} from "@/utils/crypto";

export default {
    async getSubscriptions() {
        const data = {
            method: 'get',
            url: 'subscriptions',
        }

        const response = await api.api(data)
        if (response.status === 200) {

            return response.data
        }
    },
    async getSubscription() {
        const data = {
            method: 'get',
            url: 'subscription',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            sessionStorage.setItem('subscription', encrypt(JSON.stringify(response.data)))

            return response.data
        }
    },
    async getPackages() {
        const data = {
            method: 'get',
            url: 'packages',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async initPayment(json) {
        const data = {
            method: 'post',
            url: '/payment/init',
            body: json,
        }

        return await api.api(data)
    },
    async checkPayment(json) {
        const data = {
            method: 'post',
            url: '/payment/check',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async freeSubscription() {
        const data = {
            method: 'post',
            url: '/subscription',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            await this.getSubscription()
            window.location.reload()
        }
    },
}
