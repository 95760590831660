<template>
  <!-- Payment Modal -->
  <Modal :open="stkModal" @closed="stkModal = false" dialog-size="modal-md">
    <div class="modal-header">
      <h5 class="modal-title m-0">MPESA Payment</h5>
      <button @click="stkModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
      </button>
    </div>
    <div class="modal-body">
      <div role="alert" class="alert alert-info">
        <div class="content text-center">
          Get one month free! By subscribing for 12 months.
        </div>
      </div>
      <hr>
      <form @submit.prevent="">
        <div class="row" v-if="!paymentProcessing">
          <div class="form-group col-md-12">
            <label for="phone" class="form-label fw-bold h5 mt-3">Phone number:</label>
            <input type="text" class="form-control form-control-lg" v-model="phone">
          </div>
          <div class="form-group col-md-12">
            <label for="duration" class="form-label fw-bold h5 mt-3">Months:</label>
            <select data="" class="form-select form-select-lg" v-model="duration">
              <option value="1" selected>1 Month</option>
              <option value="6">6 Months</option>
              <option value="12">12 Months</option>
            </select>
          </div>
          <div class="form-group col-md-12">
            <label for="price" class="form-label h5 mt-3"><strong>Price:</strong> {{ formatNumber(price) }}</label>
            <hr>
            <div class="float-end">
              <h5 class="text-muted fst-italic">Total: {{ formatNumber(duration * price) }}</h5>
              <h5 class="text-muted fst-italic">Discount: {{ formatNumber(duration === "12" ? price : 0) }}</h5>
            </div>
          </div>
          <div class="form-group col-md-12">
            <label for="total" class="form-label fw-bold h4 mt-3 float-end">Total: {{ formatNumber(amount) }}</label>
          </div>
        </div>
        <div class="row" v-else>
          <div class="d-flex align-content-center justify-content-center pb-3">
            <Spinner stroke="#07b35d" size="75" spinner-class="mb-1" />
          </div>
          <div class="bg-dark-subtle text-center h4 p-4" style="border-radius: 10px">Enter your MPESA PIN and wait for payment to process.</div>
        </div>
      </form>
    </div>
    <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
      <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="stkModal = false">Cancel</button>
      <button type="button" class="btn btn-primary text-uppercase" :class="!paymentProcessing ? '' : 'disabled'" @click="renewSubscription">Checkout</button>
    </div>
  </Modal>
  <!-- Payment Modal End -->
</template>

<script>
import subscriptionService from "@/api/subscriptionService";
import notify from "@/utils/notify";
import {decrypt} from "@/utils/crypto";
import formatNumber from "@/utils/formatNumber";

export default {
  name: "Stk-Modal",
  props: {
    package_id: {
      type: String,
      required: true
    },
    package_name: {
      type: String,
      required: true
    },
    price: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      stkModal: false,
      paymentProcessing: false,
      organization_id: '',
      duration: 1,
      amount: 0,
      phone: ''
    }
  },

  methods: {
    formatNumber,
    stkModalOpen() {
      this.stkModal = true
    },
    renewSubscription: async function () {
      let response = await subscriptionService.initPayment({
        organization_id: this.organization.id,
        package_id: this.package_id,
        type: this.type,
        package: this.package_name,
        duration: this.duration,
        amount: this.amount,
        phone: this.phone,
      })
      if (response.status === 201) {
        notify.alert.success('STK payment initiated successfully')
        this.paymentProcessing = true
        await this.checkPayment(response.data.id)
      }
    },
    async checkPayment(id) {
      let self = this
      setTimeout(async function () {
        let response = await subscriptionService.checkPayment({
          payment_details_id: id
        })
        if (response.status === 'Pending') {
          await self.checkPayment(id)
        } else {
          self.$router.push('/paymentSuccess')
        }
      }, 3000);
    }
  },

  async created() {
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    this.phone = this.organization.phone
    this.amount = this.price
  },

  watch: {
    duration: function () {
      if (this.duration === "12") {
        this.amount = this.price * 11
      } else {
        this.amount = this.price * this.duration
      }
    },
    price: function () {
      this.amount = this.price * this.duration
    },
  }
}
</script>
